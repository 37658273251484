.input {
    @apply bg-slate-100 outline-none shadow-none border-none py-3 px-2 w-full my-2 rounded-lg;
}

.input-password {
    @apply w-full bg-transparent outline-none shadow-none border-none py-3 px-2 absolute left-0 top-0 h-full
}

.dropdown-container {
    direction: rtl;
    background: none !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.dropdown-content .panel-content {
    background: #fff;
    border: none !important;

}

.search {
    border: none !important;
}

.search input {
    background: #fff !important;
    border: none !important;
    box-shadow: none !important;
}

input[type="file"] {
    opacity: 0;
}

.rmdp-input {
    border: none !important;
    margin-top: 10px !important;
    height: calc(2.5rem + 2px) !important;
    @apply bg-slate-100 outline-none shadow-none border-none py-3 px-2 w-full my-2 rounded-lg;
}

.rmdp-container {
    display: unset !important;
    height: unset !important;
}