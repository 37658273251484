@tailwind base;


@tailwind components;
@tailwind utilities;

/* Firefox */
* {
    scrollbar-width: thin;

}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

*::-webkit-scrollbar-track {

    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 14px;
    background: #000;
}

/* * {
    user-select: none;
} */

body {
    font-family: 'vazir';
    @apply bg-whiteBack
}

table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;

}

table th:first-child {
    border-radius: 0 10px 10px 0;
}

table th:last-child {
    border-radius: 10px 0 0 10px;
}

table th {
    white-space: nowrap;
    min-width: 100px;
}

table td {
    padding: 15px 0;
    white-space: nowrap;
    min-width: 100px;
}

.card {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    @apply p-4 bg-white rounded-xl;
}

.card-limit::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15%;
    background: linear-gradient(180deg, rgba(227, 229, 245, 0.00) 0%, #E3E5F5 100%);
}
.sun-editor .se-toolbar-shadow{
    z-index: 0!important;
}
.sun-editor .se-toolbar{
    z-index: 0!important;
    
}
.sun-editor .se-wrapper{
    z-index: 0!important; 
}

.sun-editor .se-toolbar {
    z-index: 1 !important;
}